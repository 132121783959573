import React from 'react';
import './HomePage.css';
import FileInput from "../../components/FileInput/FileInput";
import SharedLinks from "../../components/SharedLinks/SharedLinks";
import MainLayout from "../../layouts/MainLayout/MainLayout";

const HomePage = () => {
    return (
        <MainLayout>
            <div className="container mx-auto home">
                <div className="flex justify-between gap-2 home-wrapper">
                    <div className="flex flex-col w-1/2 content-block justify-between">
                        <div>
                            <div className="mb-6">
                                <h1 className="font-semibold mb-0.5 text-7xl z-10 relative title neutral-black">
                                    Read Online
                                </h1>
                                <img src="/title-vector.png" alt="litera" className="-mt-5 title-vector"/>
                            </div>

                            <div className="font-semibold text-6xl flex flex-col gap-3 sub-titles mb-8 neutral-black">
                                <h2>Anything</h2>
                                <h2>Anytime</h2>
                                <h2>Anywhere</h2>
                            </div>

                            <p className="text-xl neutral-grey mb-10 main-text">
                                Imagine the possibilities of having your entire collection
                                at hand. You can read and share
                                eBooks across all
                                devices with Litera Reader!
                            </p>
                        </div>

                        <div className="desktop-shared-links">
                            <SharedLinks/>
                        </div>
                    </div>

                    <div className="w-1/2 file-upload-block">
                        <FileInput/>
                    </div>
                </div>
            </div>

            <div className="mobile-shared-links hidden">
                <SharedLinks/>
            </div>
        </MainLayout>
    );
};

export default HomePage;