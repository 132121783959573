import React from 'react';
import { Link } from 'react-router-dom';

const Header = () => {
    return (
        <div className="container mx-auto flex justify-between items-center h-[50px] text-base mt-4 mb-10 header">
            <Link to="/" className="font-bold font-montserrat neutral-grey">
                LITERA Reader
            </Link>
            <div className="flex items-center gap-16">
                <Link to="/api-usage" className="neutral-grey how-use-api-link hidden">
                    How to use API
                </Link>
                <button type="button"
                        className="hidden py-2 disabled:text-grey-500 px-6 me-2 font-semibold text-blue-500 focus:outline-none bg-inherit rounded-md border border-blue-500 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700">
                    Log In
                </button>
            </div>
        </div>
    );
};

export default Header;