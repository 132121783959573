import React from 'react';

const CloudIconError = () => {
    return (
        <svg width="97" height="96" viewBox="0 0 97 96" fill="none" xmlns="http://www.w3.org/2000/svg">
            <mask id="mask0_21082_2751" maskUnits="userSpaceOnUse" x="0" y="0" width="97"
                  height="96">
                <path d="M96.5 0H0.5V96H96.5V0Z" fill="white"/>
            </mask>
            <g mask="url(#mask0_21082_2751)">
                <mask id="mask1_21082_2751" maskUnits="userSpaceOnUse" x="0" y="0"
                      width="97" height="96">
                    <path d="M0.5 0H96.5V96H0.5V0Z" fill="white"/>
                </mask>
                <g mask="url(#mask1_21082_2751)">
                    <path d="M58.7314 46.0318L43.3478 61.4087M59.3113 61.3994L43.956 46.0319" stroke="#E26464"
                          strokeWidth="2.89116" strokeLinecap="round"/>
                    <path
                        d="M33.7023 40.9534C33.6695 40.8487 33.6359 40.7432 33.6023 40.6377C32.5537 37.3214 29.4617 34.9116 25.7993 34.9116M75.7563 36.9339C77.1542 40.1446 77.9293 43.6883 77.9293 47.4139C77.9293 47.4139 77.9364 49.8496 77.1464 52.9579"
                        stroke="#8895A7" strokeWidth="2.73488" strokeLinecap="round" strokeLinejoin="round"/>
                    <path
                        d="M75.7491 37.1152C71.7429 27.7236 62.4271 21.1395 51.572 21.1395C41.4263 21.1395 32.6302 26.8937 28.252 35.3133C27.4566 35.0546 26.6087 34.9116 25.7265 34.9116C21.2109 34.9116 17.5508 38.5725 17.5508 43.0881C17.5508 43.6929 17.6211 44.2805 17.7454 44.8478C12.8796 47.4093 9.55713 52.5071 9.55713 58.3879C9.55713 66.8379 16.4076 73.6884 24.8576 73.6884C29.3374 73.6884 66.0098 73.6884 68.8275 73.6884C79.2889 73.6884 87.7701 65.2079 87.7701 54.7466C87.7701 46.7287 82.7856 39.8797 75.7491 37.1152Z"
                        stroke="#8895A7" strokeWidth="2.73488" strokeLinecap="round" strokeLinejoin="round"/>
                </g>
            </g>
        </svg>
    );
};

export default CloudIconError;