import React from 'react';
import {FILE_FORMATS} from "../constants/formats";

const FileFormats = () => {
    return (
        <div className="flex flex-wrap py-7 justify-center gap-3 px-12 formats-block neutral-grey">
            {FILE_FORMATS.map((format, index) =>
                <span
                    key={index}
                    className="file-format font-semibold py-2 px-4 rounded-full"
                >
                    {format}
                </span>
            )}
        </div>
    );
};

export default FileFormats;