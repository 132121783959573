import React from 'react';
import MainLayout from "../layouts/MainLayout/MainLayout";

const HowUseApiPage = () => {
    return (
        <MainLayout>
            <div className="container mx-auto bg-white mt-10 p-7 how-use-api-page">
                <h1 className="font-bold text-3xl mb-6 neutral-black">How to use API</h1>

                <p className="neutral-dark-grey">
                    On this page we talk about how to use our service using the API. I don’t know what exactly needs to
                    be written here, but apparently there is a person who knows how this thing works.
                </p>

                <p className="neutral-dark-grey">
                    I also heard that the user can simply insert a link to initiate reading.
                </p>
            </div>
        </MainLayout>
    );
};

export default HowUseApiPage;