import React, {useEffect, useState} from 'react';

const Notification = ({message}) => {
    const [visible, setVisible] = useState(true);

    useEffect(() => {
        const timer = setTimeout(() => {
            setVisible(false);
        }, 1500);

        return () => clearTimeout(timer);
    }, [visible]);

    return (
        <>
            {visible && (
                <div
                    id="toast-success"
                    className="fixed bottom-10 left-0 right-0 flex justify-center items-center p-3 mb-4 text-white bg-black rounded-lg shadow max-w-sm mx-auto z-10"
                    role="alert"
                >
                    <div className="text-sm font-normal">{message}</div>
                </div>
            )}
        </>
    );
};

export default Notification;