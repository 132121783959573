import axios from 'axios';

export const sendFile = async (file, onProgress) => {
    const formData = new FormData();
    formData.append('file', file);

    const config = {
        headers: {
            'Content-Type': 'multipart/form-data'
        },
        onUploadProgress: progressEvent => {
            const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
            onProgress(percentCompleted);
        }
    };

    const response = await axios.post(process.env.REACT_APP_FILES_API_URL, formData, config);

    return response.data;
};