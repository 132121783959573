import React from 'react';
import { Link } from "react-router-dom";
import SocialMedia from "./SocialMedia/SocialMedia";

const Footer = () => {
    return (
        <div className="footer">
            <hr/>

            <div className="container mx-auto flex justify-between items-center h-[70px] text-sm neutral-grey footer-content">
                <div className="text-nowrap flex flex-col gap-2 footer-content_text">
                    <span className="desktop-footer-text">Copyright © 2024 Litera Reader</span>
                    <span className="mobile-footer-text hidden">2024 Litera Reader</span>
                    <a href="mailto:support@litera-library.com" className="support-mail-mobile hidden">
                        support@litera-library.com
                    </a>
                </div>
                <div className="flex items-center gap-8 footer-links">
                    <SocialMedia/>
                    <a href="mailto:support@litera-library.com" className="support-mail-desktop">
                        support@litera-library.com
                    </a>
                    <Link to="/terms" className="terms-link">Terms</Link>
                </div>
            </div>
        </div>
    );
};

export default Footer;