import React from 'react';

const CloudIcon = () => {
    return (
        <svg
            width="13"
            height="12"
            viewBox="0 0 13 12"
            fill="currentColor"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M12.4 0.393031L10.4386 10.6329C10.4386 10.6329 10.1641 11.3428 9.41023 11.0023L4.88467 7.40905L4.86368 7.39846C5.47498 6.83005 10.2152 2.41648 10.4224 2.21642C10.7431 1.90657 10.544 1.72212 10.1717 1.95617L3.1697 6.56089L0.468353 5.61966C0.468353 5.61966 0.0432416 5.46307 0.0023448 5.12257C-0.0390901 4.78153 0.482344 4.59707 0.482344 4.59707L11.4949 0.123313C11.4949 0.123313 12.4 -0.288509 12.4 0.393031Z"
            />
        </svg>
    );
};

export default CloudIcon;