import React from 'react';
import MainLayout from "../layouts/MainLayout/MainLayout";
import {PROHIBITED_ACTIVES, TABLE_OF_CONTENTS_LIST, USER_GENERATED_CONTRIBUTIONS} from "../constants/tableContents";

const TermsOfUsePage = () => {
    return (
        <MainLayout>
            <div className="container mx-auto bg-white mt-10 mb-7 p-7 w-7/12 terms-page">
                <div className="flex justify-between terms-title neutral-black">
                    <h1 className="font-bold text-3xl mb-6 uppercase">Terms of use</h1>

                    <i className="neutral-dark-grey">Last updated March 05, 2023</i>
                </div>

                <h3 className="font-bold text-xl uppercase my-5 neutral-black">Agreement to our legal terms</h3>

                <div className="neutral-dark-grey">
                    <p className="mb-4">We are Z-Library ('Company', 'we', 'us', or 'our'), we operate this website (the
                        'Site'), as well as
                        any
                        other related products and services that refer or link to these legal terms (the 'Legal Terms')
                        (collectively, the 'Services').</p>
                    <p className="mb-4">You can contact us by email at
                        <a href="mailto:support@litera-library.com"> support@litera-library.com</a>.
                    </p>
                    <p className="mb-4">These Legal Terms constitute a legally binding agreement made between you,
                        whether
                        personally or on
                        behalf of an entity ('you'), and Z-Library, concerning your access to and use of the Services.
                        You
                        agree
                        that by accessing the Services, you have read, understood, and agreed to be bound by all of
                        these
                        Legal
                        Terms. IF YOU DO NOT AGREE WITH ALL OF THESE LEGAL TERMS, THEN YOU ARE EXPRESSLY PROHIBITED FROM
                        USING
                        THE SERVICES AND YOU MUST DISCONTINUE USE IMMEDIATELY.</p>
                    <p className="mb-4">Supplemental terms and conditions or documents that may be posted on the
                        Services
                        from time to time
                        are
                        hereby expressly incorporated herein by reference. We reserve the right, in our sole discretion,
                        to
                        make
                        changes or modifications to these Legal Terms from time to time. We will alert you about any
                        changes
                        by
                        updating the 'Last updated' date of these Legal Terms, and you waive any right to receive
                        specific
                        notice of each such change. It is your responsibility to periodically review these Legal Terms
                        to
                        stay
                        informed of updates. You will be subject to, and will be deemed to have been made aware of and
                        to
                        have
                        accepted, the changes in any revised Legal Terms by your continued use of the Services after the
                        date
                        such revised Legal Terms are posted.</p>
                    <p className="mb-4">All users who are minors in the jurisdiction in which they reside (generally
                        under
                        the age of 18)
                        must
                        have the permission of, and be directly supervised by, their parent or guardian to use the
                        Services.
                        If
                        you are a minor, you must have your parent or guardian read and agree to these Legal Terms prior
                        to
                        you
                        using the Services.</p>
                    <p className="mb-4">We recommend that you print a copy of these Legal Terms for your records.</p>
                </div>

                <h3 className="font-semibold text-xl uppercase my-5 neutral-black">Table of contents</h3>

                <ul className="mb-5">
                    {TABLE_OF_CONTENTS_LIST.map((item, index) =>
                        <li key={index} className='mb-1'>
                            <a href={item.link} className="underline text-blue-600 active:text-gray-500">
                                {index + 1}.{' ' + item.title}
                            </a>
                        </li>
                    )}
                </ul>

                <div className="mb-5" id="our-services">
                    <h3 className="font-semibold text-xl uppercase my-5">1. Our Service</h3>
                    <div className="text-gray-500">
                        <p className="mb-4">
                            The information provided when using the Services is not intended for distribution to or use
                            by
                            any person or entity in any jurisdiction or country where such distribution or use would be
                            contrary to law or regulation or which would subject us to any registration requirement
                            within
                            such jurisdiction or country. Accordingly, those persons who choose to access the Services
                            from
                            other locations do so on their own initiative and are solely responsible for compliance with
                            local laws, if and to the extent local laws are applicable.
                        </p>
                        <p className="mb-4">
                            The Services are not tailored to comply with industry-specific regulations (Health Insurance
                            Portability and Accountability Act (HIPAA), Federal Information Security Management Act
                            (FISMA),
                            etc.), so if your interactions would be subjected to such laws, you may not use the
                            Services.
                            You may not use the Services in a way that would violate the Gramm-Leach-Bliley Act (GLBA).
                        </p>
                    </div>
                </div>

                <div className="mb-5" id="intellectual-property-rights">
                    <h3 className="font-semibold text-xl uppercase my-5">2. INTELLECTUAL PROPERTY RIGHTS</h3>
                    <div className="text-gray-500 font-sans">
                        <h5 className="font-medium text-black my-3">Our intellectual property</h5>
                        <p className="mb-4">
                            We are the owner or the licensee of all intellectual property rights in our Services,
                            including
                            all source code, databases, functionality, software, website designs, audio, video, text,
                            photographs, and graphics in the Services (collectively, the 'Content'), as well as the
                            trademarks, service marks, and logos contained therein (the 'Marks').
                        </p>
                        <p className="mb-4">
                            The Content and Marks are provided in or through the Services 'AS IS' for your personal,
                            non-commercial use only.
                        </p>
                        <h5 className="font-medium text-black my-3">Your use of our Services</h5>
                        <p className="mb-4">
                            Subject to your compliance with these Legal Terms, including the 'PROHIBITED ACTIVITIES'
                            section
                            below, we grant you a non-exclusive, non-transferable, revocable licence to:
                            access the Services; and
                            download or print a copy of any portion of the Content to which you have properly gained
                            access.
                            solely for your personal, non-commercial use.
                        </p>
                        <p className="mb-4">
                            Except as set out in this section or elsewhere in our Legal Terms, no part of the Services
                            and
                            no Content or Marks may be copied, reproduced, aggregated, republished, uploaded, posted,
                            publicly displayed, encoded, translated, transmitted, distributed, sold, licensed, or
                            otherwise
                            exploited for any commercial purpose whatsoever, without our express prior written
                            permission.
                        </p>
                        <p className="mb-4">
                            If you wish to make any use of the Services, Content, or Marks other than as set out in this
                            section or elsewhere in our Legal Terms, please address your request to:
                            <a href="mailto:support@litera-library.com"> support@litera-library.com </a>
                            If we
                            ever grant you the permission to post, reproduce, or publicly display any part of our
                            Services
                            or Content, you must identify us as the owners or licensors of the Services, Content, or
                            Marks
                            and ensure that any copyright or proprietary notice appears or is visible on posting,
                            reproducing, or displaying our Content.
                        </p>
                        <p>We reserve all rights not expressly granted to you in and to the Services, Content, and
                            Marks.</p>
                        <p>Any breach of these Intellectual Property Rights will constitute a material breach of our
                            Legal
                            Terms and your right to use our Services will terminate immediately.
                        </p>
                        <h5 className="font-medium text-black my-3">Your submissions and contributions</h5>
                        <p className="mb-4">Please review this section and the 'PROHIBITED ACTIVITIES' section carefully
                            prior to using our
                            Services to understand the (a) rights you give us and (b) obligations you have when you post
                            or
                            upload any content through the Services.
                        </p>
                        <p className="mb-4">
                            Submissions: By directly sending us any question, comment, suggestion, idea, feedback, or
                            other
                            information about the Services ('Submissions'), you agree to assign to us all intellectual
                            property rights in such Submission. You agree that we shall own this Submission and be
                            entitled
                            to its unrestricted use and dissemination for any lawful purpose, commercial or otherwise,
                            without acknowledgment or compensation to you.
                        </p>
                        <p className="mb-4">
                            Contributions: The Services may invite you to chat, contribute to, or participate in blogs,
                            message boards, online forums, and other functionality during which you may create, submit,
                            post, display, transmit, publish, distribute, or broadcast content and materials to us or
                            through the Services, including but not limited to text, writings, video, audio,
                            photographs,
                            music, graphics, comments, reviews, rating suggestions, personal information, or other
                            material
                            ('Contributions'). Any Submission that is publicly posted shall also be treated as a
                            Contribution.
                        </p>
                        <p>
                            You understand that Contributions may be viewable by other users of the Services and
                            possibly
                            through third-party websites.
                        </p>
                        <p className="mb-4">
                            When you post Contributions, you grant us a licence (including use of your name, trademarks,
                            and
                            logos): By posting any Contributions, you grant us an unrestricted, unlimited, irrevocable,
                            perpetual, non-exclusive, transferable, royalty-free, fully-paid, worldwide right, and
                            licence
                            to: use, copy, reproduce, distribute, sell, resell, publish, broadcast, retitle, store,
                            publicly
                            perform, publicly display, reformat, translate, excerpt (in whole or in part), and exploit
                            your
                            Contributions (including, without limitation, your image, name, and voice) for any purpose,
                            commercial, advertising, or otherwise, to prepare derivative works of, or incorporate into
                            other
                            works, your Contributions, and to sublicence the licences granted in this section. Our use
                            and
                            distribution may occur in any media formats and through any media channels.
                        </p>
                        <p className="mb-4">
                            This licence includes our use of your name, company name, and franchise name, as applicable,
                            and
                            any of the trademarks, service marks, trade names, logos, and personal and commercial images
                            you
                            provide.
                        </p>
                        <p className="mb-4">
                            You are responsible for what you post or upload: By sending us Submissions and/or posting
                            Contributions through any part of the Services or making Contributions accessible through
                            the
                            Services by linking your account through the Services to any of your social networking
                            accounts,
                            you:
                        </p>
                        <p className="mb-4">
                            confirm that you have read and agree with our 'PROHIBITED ACTIVITIES' and will not post,
                            send,
                            publish, upload, or transmit through the Services any Submission nor post any Contribution
                            that
                            is illegal, harassing, hateful, harmful, defamatory, obscene, bullying, abusive,
                            discriminatory,
                            threatening to any person or group, sexually explicit, false, inaccurate, deceitful, or
                            misleading;
                        </p>
                        <p className="mb-4">
                            to the extent permissible by applicable law, waive any and all moral rights to any such
                            Submission and/or Contribution;
                        </p>
                        <p className="mb-4">warrant that any such Submission and/or Contributions are original to you or
                            that you have the
                            necessary rights and licences to submit such Submissions and/or Contributions and that you
                            have
                            full authority to grant us the above-mentioned rights in relation to your Submissions and/or
                            Contributions; and
                        </p>
                        <p className="mb-4">warrant and represent that your Submissions and/or Contributions do not
                            constitute confidential
                            information.
                        </p>
                        <p className="mb-4">We may remove or edit your Content: Although we have no obligation to
                            monitor
                            any Contributions,
                            we shall have the right to remove or edit any Contributions at any time without notice if in
                            our
                            reasonable opinion we consider such Contributions harmful or in breach of these Legal Terms.
                            If
                            we remove or edit any such Contributions, we may also suspend or disable your account and
                            report
                            you to the authorities.
                        </p>
                        <h5 className="font-medium text-black uppercase my-3">Copyright infringement</h5>
                        <p>We respect the intellectual property rights of others. If you believe that any material
                            available
                            on or through the Services infringes upon any copyright you own or control, please
                            immediately
                            refer to the 'COPYRIGHT INFRINGEMENTS' section below.
                        </p>
                    </div>

                    <div className="mb-5" id="user-representations">
                        <h3 className="font-semibold text-xl uppercase my-5">3. User representations</h3>
                        <div className="text-gray-500 font-sans">
                            <p className="mb-4">
                                By using the Services, you represent and warrant that: (1) all registration information
                                you
                                submit will be true, accurate, current, and complete; (2) you will maintain the accuracy
                                of
                                such information and promptly update such registration information as necessary; (3) you
                                have the legal capacity and you agree to comply with these Legal Terms; (4) you are not
                                a
                                minor in the jurisdiction in which you reside, or if a minor, you have received parental
                                permission to use the Services; (5) you will not access the Services through automated
                                or
                                non-human means, whether through a bot, script or otherwise; (6) you will not use the
                                Services for any illegal or unauthorised purpose; and (7) your use of the Services will
                                not
                                violate any applicable law or regulation.
                            </p>
                            <p className="mb-4">
                                If you provide any information that is untrue, inaccurate, not current, or incomplete,
                                we
                                have the right to suspend or terminate your account and refuse any and all current or
                                future
                                use of the Services (or any portion thereof).
                            </p>
                        </div>
                    </div>

                    <div className="mb-5" id="user-registration">
                        <h3 className="font-semibold text-xl uppercase my-5">4. User registration</h3>
                        <div className="text-gray-500 font-sans">
                            <p className="mb-4">
                                You may be required to register to use the Services. You agree to keep your password
                                confidential and will be responsible for all use of your account and password. We
                                reserve
                                the right to remove, reclaim, or change a username you select if we determine, in our
                                sole
                                discretion, that such username is inappropriate, obscene, or otherwise objectionable.
                            </p>
                        </div>
                    </div>

                    <div className="mb-5" id="purchases-and-payment">
                        <h3 className="font-semibold text-xl uppercase my-5">5. Purchases and payment. Donations</h3>
                        <div className="text-gray-500 font-sans">
                            <p className="mb-4">
                                You agree to pay all charges at the prices then in effect for your purchases and any
                                applicable shipping fees, and you authorise us to charge your chosen payment provider
                                for
                                any such amounts upon placing your order. We reserve the right to correct any errors or
                                mistakes in pricing, even if we have already requested or received payment.
                            </p>
                            <p className="mb-4">
                                We reserve the right to refuse any order placed through the Services. We may, in our
                                sole
                                discretion, limit or cancel quantities purchased per person, per household, or per
                                order.
                                These restrictions may include orders placed by or under the same customer account, the
                                same
                                payment method, and/or orders that use the same billing or shipping address. We reserve
                                the
                                right to limit or prohibit orders that, in our sole judgement, appear to be placed by
                                dealers, resellers, or distributors.
                            </p>
                            <p className="mb-4">
                                All donations accepted by the Z-Library project are considered as donations to the
                                Z-Library
                                team. The Z-Library team may spend donations in any way we decide, including but not
                                limited
                                to using donations to pay for Z-Library servers and domains, reward team members, buy
                                prizes/gifts for users, reward authors and rights holders, maintain other projects not
                                related to Z-Library, buy beer and any kind of personal spending of team members.
                            </p>
                        </div>
                    </div>

                    <div className="mb-5" id="cancellation">
                        <h3 className="font-semibold text-xl uppercase my-5">6. Cancellation</h3>
                        <div className="text-gray-500 font-sans">
                            <p className="mb-4">
                                If you are unsatisfied with our Services, please email us at
                                <a href="mailto:support@litera-library.com"> support@litera-library.com</a>
                            </p>
                        </div>
                    </div>

                    <div className="mb-5" id="prohibited-activities">
                    <h3 className="font-semibold text-xl uppercase my-5">7. Prohibited activities</h3>
                        <div className="text-gray-500 font-sans">
                            <p className="mb-4">
                                You may not access or use the Services for any purpose other than that for which we make
                                the
                                Services available. The Services may not be used in connection with any commercial
                                endeavours except those that are specifically endorsed or approved by us.
                            </p>
                            <div className="mb-4">
                                As a user of the Services, you agree not to:
                                <ul className="text-gray-500 list-disc list-inside ms-8 dark:text-gray-400 flex flex-col gap-2">
                                    {PROHIBITED_ACTIVES.map((item, index) =>
                                        <li key={index}>{item}</li>
                                    )}
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div className="mb-5" id="user-generated-contributions">
                        <h3 className="font-semibold text-xl uppercase my-5">8. User generated contributions</h3>
                        <div className="text-gray-500 font-sans">
                            <p className="mb-4">
                                The Services may invite you to chat, contribute to, or participate in blogs, message
                                boards,
                                online forums, and other functionality, and may provide you with the opportunity to
                                create,
                                submit, post, display, transmit, perform, publish, distribute, or broadcast content and
                                materials to us or on the Services, including but not limited to text, writings, video,
                                audio, photographs, graphics, comments, suggestions, or personal information or other
                                material (collectively, 'Contributions'). Contributions may be viewable by other users
                                of
                                the Services and through third-party websites. As such, any Contributions you transmit
                                may
                                be treated as non-confidential and non-proprietary. When you create or make available
                                any
                                Contributions, you thereby represent and warrant that:
                            </p>
                            <div className="mb-4">
                                <ul className="text-gray-500 list-disc list-inside ms-8 dark:text-gray-400 flex flex-col gap-2">
                                    {USER_GENERATED_CONTRIBUTIONS.map((item, index) =>
                                        <li key={index}>{item}</li>
                                    )}
                                </ul>
                            </div>
                            <p className="mb-4">
                                Any use of the Services in violation of the foregoing violates these Legal Terms and may
                                result in, among other things, termination or suspension of your rights to use the
                                Services.
                            </p>
                        </div>
                    </div>

                    <div className="mb-5" id="contribution-licence">
                        <h3 className="font-semibold text-xl uppercase my-5">9. Contribution licence</h3>
                        <div className="text-gray-500 font-sans">
                            <p className="mb-4">
                                By posting your Contributions to any part of the Services, you automatically grant, and
                                you
                                represent and warrant that you have the right to grant, to us an unrestricted,
                                unlimited,
                                irrevocable, perpetual, non-exclusive, transferable, royalty-free, fully-paid, worldwide
                                right, and licence to host, use, copy, reproduce, disclose, sell, resell, publish,
                                broadcast, retitle, archive, store, cache, publicly perform, publicly display, reformat,
                                translate, transmit, excerpt (in whole or in part), and distribute such Contributions
                                (including, without limitation, your image and voice) for any purpose, commercial,
                                advertising, or otherwise, and to prepare derivative works of, or incorporate into other
                                works, such Contributions, and grant and authorise sublicences of the foregoing. The use
                                and
                                distribution may occur in any media formats and through any media channels.
                            </p>
                            <p className="mb-4">
                                This licence will apply to any form, media, or technology now known or hereafter
                                developed,
                                and includes our use of your name, company name, and franchise name, as applicable, and
                                any
                                of the trademarks, service marks, trade names, logos, and personal and commercial images
                                you
                                provide. You waive all moral rights in your Contributions, and you warrant that moral
                                rights
                                have not otherwise been asserted in your Contributions.
                            </p>
                            <p className="mb-4">
                                We do not assert any ownership over your Contributions. You retain full ownership of all
                                of
                                your Contributions and any intellectual property rights or other proprietary rights
                                associated with your Contributions. We are not liable for any statements or
                                representations
                                in your Contributions provided by you in any area on the Services. You are solely
                                responsible for your Contributions to the Services and you expressly agree to exonerate
                                us
                                from any and all responsibility and to refrain from any legal action against us
                                regarding
                                your Contributions.
                            </p>
                            <p className="mb-4">
                                We have the right, in our sole and absolute discretion, (1) to edit, redact, or
                                otherwise
                                change any Contributions; (2) to re-categorise any Contributions to place them in more
                                appropriate locations on the Services; and (3) to pre-screen or delete any Contributions
                                at
                                any time and for any reason, without notice. We have no obligation to monitor your
                                Contributions.
                            </p>
                        </div>
                    </div>

                    <div className="mb-5" id="guidelines-for-reviews">
                        <h3 className="font-semibold text-xl uppercase my-5">10. Guidelines for reviews</h3>
                        <div className="text-gray-500 font-sans">
                            <p className="mb-4">
                                We may provide you areas on the Services to leave reviews or ratings. When posting a
                                review,
                                you must comply with the following criteria: (1) you should have firsthand experience
                                with
                                the person/entity being reviewed; (2) your reviews should not contain offensive
                                profanity,
                                or abusive, racist, offensive, or hateful language; (3) your reviews should not contain
                                discriminatory references based on religion, race, gender, national origin, age, marital
                                status, sexual orientation, or disability; (4) your reviews should not contain
                                references to
                                illegal activity; (5) you should not be affiliated with competitors if posting negative
                                reviews; (6) you should not make any conclusions as to the legality of conduct; (7) you
                                may
                                not post any false or misleading statements; and (8) you may not organise a campaign
                                encouraging others to post reviews, whether positive or negative.
                            </p>
                            <p className="mb-4">
                                We may accept, reject, or remove reviews in our sole discretion. We have absolutely no
                                obligation to screen reviews or to delete reviews, even if anyone considers reviews
                                objectionable or inaccurate. Reviews are not endorsed by us, and do not necessarily
                                represent our opinions or the views of any of our affiliates or partners. We do not
                                assume
                                liability for any review or for any claims, liabilities, or losses resulting from any
                                review. By posting a review, you hereby grant to us a perpetual, non-exclusive,
                                worldwide,
                                royalty-free, fully paid, assignable, and sublicensable right and licence to reproduce,
                                modify, translate, transmit by any means, display, perform, and/or distribute all
                                content
                                relating to review.
                            </p>
                        </div>
                    </div>

                    <div className="mb-5" id="third-party-websites-and-content">
                        <h3 className="font-semibold text-xl uppercase my-5">11. Third party websites and content</h3>
                        <div className="text-gray-500 font-sans">
                            <p className="mb-4">
                                The Services may contain (or you may be sent via the Site) links to other websites
                                ('Third-Party Websites') as well as articles, photographs, text, graphics, pictures,
                                designs, music, sound, video, information, applications, software, and other content or
                                items belonging to or originating from third parties ('Third-Party Content'). Such
                                Third-Party Websites and Third-Party Content are not investigated, monitored, or checked
                                for
                                accuracy, appropriateness, or completeness by us, and we are not responsible for any
                                Third-Party Websites accessed through the Services or any Third-Party Content posted on,
                                available through, or installed from the Services, including the content, accuracy,
                                offensiveness, opinions, reliability, privacy practices, or other policies of or
                                contained
                                in the Third-Party Websites or the Third-Party Content. Inclusion of, linking to, or
                                permitting the use or installation of any Third-Party Websites or any Third-Party
                                Content
                                does not imply approval or endorsement thereof by us. If you decide to leave the
                                Services
                                and access the Third-Party Websites or to use or install any Third-Party Content, you do
                                so
                                at your own risk, and you should be aware these Legal Terms no longer govern. You should
                                review the applicable terms and policies, including privacy and data gathering
                                practices, of
                                any website to which you navigate from the Services or relating to any applications you
                                use
                                or install from the Services. Any purchases you make through Third-Party Websites will
                                be
                                through other websites and from other companies, and we take no responsibility
                                whatsoever in
                                relation to such purchases which are exclusively between you and the applicable third
                                party.
                                You agree and acknowledge that we do not endorse the products or services offered on
                                Third-Party Websites and you shall hold us blameless from any harm caused by your
                                purchase
                                of such products or services. Additionally, you shall hold us blameless from any losses
                                sustained by you or harm caused to you relating to or resulting in any way from any
                                Third-Party Content or any contact with Third-Party Websites.
                            </p>
                        </div>
                    </div>

                    <div className="mb-5" id="services-management">
                        <h3 className="font-semibold text-xl uppercase my-5">12. Services management</h3>
                        <div className="text-gray-500 font-sans">
                            <p className="mb-4">
                                We reserve the right, but not the obligation, to: (1) monitor the Services for
                                violations of
                                these Legal Terms; (2) take appropriate legal action against anyone who, in our sole
                                discretion, violates the law or these Legal Terms, including without limitation,
                                reporting
                                such user to law enforcement authorities; (3) in our sole discretion and without
                                limitation,
                                refuse, restrict access to, limit the availability of, or disable (to the extent
                                technologically feasible) any of your Contributions or any portion thereof; (4) in our
                                sole
                                discretion and without limitation, notice, or liability, to remove from the Services or
                                otherwise disable all files and content that are excessive in size or are in any way
                                burdensome to our systems; and (5) otherwise manage the Services in a manner designed to
                                protect our rights and property and to facilitate the proper functioning of the
                                Services.
                            </p>
                        </div>
                    </div>

                    <div className="mb-5" id="copyright-infractions">
                        <h3 className="font-semibold text-xl uppercase my-5">13. Copyright infractions</h3>
                        <div className="text-gray-500 font-sans">
                            <p className="mb-4">
                                We respect the intellectual property rights of others. If you believe that any material
                                available on or through the Services infringes upon any copyright you own or control,
                                please
                                immediately notify us using the contact information provided below (a 'Notification'). A
                                copy of your Notification will be sent to the person who posted or stored the material
                                addressed in the Notification. Please be advised that pursuant to applicable law you may
                                be
                                held liable for damages if you make material misrepresentations in a Notification. Thus,
                                if
                                you are not sure that material located on or linked to by the Services infringes your
                                copyright, you should consider first contacting an attorney.
                            </p>
                        </div>
                    </div>

                    <div className="mb-5" id="term-and-termination">
                        <h3 className="font-semibold text-xl uppercase my-5">14. Term and termination</h3>
                        <div className="text-gray-500 font-sans">
                            <p className="mb-4">
                                These Legal Terms shall remain in full force and effect while you use the Services.
                                WITHOUT
                                LIMITING ANY OTHER PROVISION OF THESE LEGAL TERMS, WE RESERVE THE RIGHT TO, IN OUR SOLE
                                DISCRETION AND WITHOUT NOTICE OR LIABILITY, DENY ACCESS TO AND USE OF THE SERVICES
                                (INCLUDING BLOCKING CERTAIN IP ADDRESSES), TO ANY PERSON FOR ANY REASON OR FOR NO
                                REASON,
                                INCLUDING WITHOUT LIMITATION FOR BREACH OF ANY REPRESENTATION, WARRANTY, OR COVENANT
                                CONTAINED IN THESE LEGAL TERMS OR OF ANY APPLICABLE LAW OR REGULATION. WE MAY TERMINATE
                                YOUR
                                USE OR PARTICIPATION IN THE SERVICES OR DELETE YOUR ACCOUNT AND ANY CONTENT OR
                                INFORMATION
                                THAT YOU POSTED AT ANY TIME, WITHOUT WARNING, IN OUR SOLE DISCRETION.
                            </p>
                            <p className="mb-4">
                                If we terminate or suspend your account for any reason, you are prohibited from
                                registering
                                and creating a new account under your name, a fake or borrowed name, or the name of any
                                third party, even if you may be acting on behalf of the third party. In addition to
                                terminating or suspending your account, we reserve the right to take appropriate legal
                                action, including without limitation pursuing civil, criminal, and injunctive redress.
                            </p>
                        </div>
                    </div>

                    <div className="mb-5" id="modifications-and-interruptions">
                        <h3 className="font-semibold text-xl uppercase my-5">15. Modifications and interruptions</h3>
                        <div className="text-gray-500 font-sans">
                            <p className="mb-4">
                                We reserve the right to change, modify, or remove the contents of the Services at any
                                time
                                or for any reason at our sole discretion without notice. However, we have no obligation
                                to
                                update any information on our Services. We will not be liable to you or any third party
                                for
                                any modification, price change, suspension, or discontinuance of the Services.
                            </p>
                            <p className="mb-4">
                                We cannot guarantee the Services will be available at all times. We may experience
                                hardware,
                                software, or other problems or need to perform maintenance related to the Services,
                                resulting in interruptions, delays, or errors. We reserve the right to change, revise,
                                update, suspend, discontinue, or otherwise modify the Services at any time or for any
                                reason
                                without notice to you. You agree that we have no liability whatsoever for any loss,
                                damage,
                                or inconvenience caused by your inability to access or use the Services during any
                                downtime
                                or discontinuance of the Services. Nothing in these Legal Terms will be construed to
                                obligate us to maintain and support the Services or to supply any corrections, updates,
                                or
                                releases in connection therewith.
                            </p>
                        </div>
                    </div>

                    <div className="mb-5" id="governing-law">
                        <h3 className="font-semibold text-xl uppercase my-5">16. Governing law</h3>
                        <div className="text-gray-500 font-sans">
                            <p className="mb-4">
                                These Legal Terms shall be governed by and defined following the laws of Russia.
                                Z-Library
                                and yourself irrevocably consent that the courts of Russia shall have exclusive
                                jurisdiction
                                to resolve any dispute which may arise in connection with these Legal Terms.
                            </p>
                        </div>
                    </div>

                    <div className="mb-5" id="dispute-resolution">
                        <h3 className="font-semibold text-xl uppercase my-5">17. Dispute resolution</h3>
                        <div className="text-gray-500 font-sans">
                            <h5 className="font-medium text-black my-3">Informal Negotiations</h5>
                            <p className="mb-4">
                                To expedite resolution and control the cost of any dispute, controversy, or claim
                                related to
                                these Legal Terms (each a 'Dispute' and collectively, the 'Disputes') brought by either
                                you
                                or us (individually, a 'Party' and collectively, the 'Parties'), the Parties agree to
                                first
                                attempt to negotiate any Dispute (except those Disputes expressly provided below)
                                informally
                                for at least thirty (30) days before initiating arbitration. Such informal negotiations
                                commence upon written notice from one Party to the other Party.
                            </p>
                        </div>
                        <div className="text-gray-500 font-sans">
                            <h5 className="font-medium text-black my-3">Restrictions</h5>
                            <p className="mb-4">
                                The Parties agree that any arbitration shall be limited to the Dispute between the
                                Parties
                                individually. To the full extent permitted by law, (a) no arbitration shall be joined
                                with
                                any other proceeding; (b) there is no right or authority for any Dispute to be
                                arbitrated on
                                a class-action basis or to utilise class action procedures; and (c) there is no right or
                                authority for any Dispute to be brought in a purported representative capacity on behalf
                                of
                                the general public or any other persons.
                            </p>
                        </div>
                        <div className="text-gray-500 font-sans">
                            <h5 className="font-medium text-black my-3">Exceptions to Informal Negotiations and
                                Arbitration</h5>
                            <p className="mb-4">
                                The Parties agree that the following Disputes are not subject to the above provisions
                                concerning informal negotiations binding arbitration: (a) any Disputes seeking to
                                enforce or
                                protect, or concerning the validity of, any of the intellectual property rights of a
                                Party;
                                (b) any Dispute related to, or arising from, allegations of theft, piracy, invasion of
                                privacy, or unauthorised use; and (c) any claim for injunctive relief. If this provision
                                is
                                found to be illegal or unenforceable, then neither Party will elect to arbitrate any
                                Dispute
                                falling within that portion of this provision found to be illegal or unenforceable and
                                such
                                Dispute shall be decided by a court of competent jurisdiction within the courts listed
                                for
                                jurisdiction above, and the Parties agree to submit to the personal jurisdiction of that
                                court.
                            </p>
                        </div>
                    </div>

                    <div className="mb-5" id="corrections">
                        <h3 className="font-semibold text-xl uppercase my-5">18. Corrections</h3>
                        <div className="text-gray-500 font-sans">
                            <p className="mb-4">
                                There may be information on the Services that contains typographical errors,
                                inaccuracies,
                                or omissions, including descriptions, pricing, availability, and various other
                                information.
                                We reserve the right to correct any errors, inaccuracies, or omissions and to change or
                                update the information on the Services at any time, without prior notice.
                            </p>
                        </div>
                    </div>

                    <div className="mb-5" id="disclaimer">
                        <h3 className="font-semibold text-xl uppercase my-5">19. Disclaimer</h3>
                        <div className="text-gray-500 font-sans">
                            <p className="mb-4">
                                THE SERVICES ARE PROVIDED ON AN AS-IS AND AS-AVAILABLE BASIS. YOU AGREE THAT YOUR USE OF
                                THE
                                SERVICES WILL BE AT YOUR SOLE RISK. TO THE FULLEST EXTENT PERMITTED BY LAW, WE DISCLAIM
                                ALL
                                WARRANTIES, EXPRESS OR IMPLIED, IN CONNECTION WITH THE SERVICES AND YOUR USE THEREOF,
                                INCLUDING, WITHOUT LIMITATION, THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A
                                PARTICULAR PURPOSE, AND NON-INFRINGEMENT. WE MAKE NO WARRANTIES OR REPRESENTATIONS ABOUT
                                THE
                                ACCURACY OR COMPLETENESS OF THE SERVICES' CONTENT OR THE CONTENT OF ANY WEBSITES OR
                                MOBILE
                                APPLICATIONS LINKED TO THE SERVICES AND WE WILL ASSUME NO LIABILITY OR RESPONSIBILITY
                                FOR
                                ANY (1) ERRORS, MISTAKES, OR INACCURACIES OF CONTENT AND MATERIALS, (2) PERSONAL INJURY
                                OR
                                PROPERTY DAMAGE, OF ANY NATURE WHATSOEVER, RESULTING FROM YOUR ACCESS TO AND USE OF THE
                                SERVICES, (3) ANY UNAUTHORISED ACCESS TO OR USE OF OUR SECURE SERVERS AND/OR ANY AND ALL
                                PERSONAL INFORMATION AND/OR FINANCIAL INFORMATION STORED THEREIN, (4) ANY INTERRUPTION
                                OR
                                CESSATION OF TRANSMISSION TO OR FROM THE SERVICES, (5) ANY BUGS, VIRUSES, TROJAN HORSES,
                                OR
                                THE LIKE WHICH MAY BE TRANSMITTED TO OR THROUGH THE SERVICES BY ANY THIRD PARTY, AND/OR
                                (6)
                                ANY ERRORS OR OMISSIONS IN ANY CONTENT AND MATERIALS OR FOR ANY LOSS OR DAMAGE OF ANY
                                KIND
                                INCURRED AS A RESULT OF THE USE OF ANY CONTENT POSTED, TRANSMITTED, OR OTHERWISE MADE
                                AVAILABLE VIA THE SERVICES. WE DO NOT WARRANT, ENDORSE, GUARANTEE, OR ASSUME
                                RESPONSIBILITY
                                FOR ANY PRODUCT OR SERVICE ADVERTISED OR OFFERED BY A THIRD PARTY THROUGH THE SERVICES,
                                ANY
                                HYPERLINKED WEBSITE, OR ANY WEBSITE OR MOBILE APPLICATION FEATURED IN ANY BANNER OR
                                OTHER
                                ADVERTISING, AND WE WILL NOT BE A PARTY TO OR IN ANY WAY BE RESPONSIBLE FOR MONITORING
                                ANY
                                TRANSACTION BETWEEN YOU AND ANY THIRD-PARTY PROVIDERS OF PRODUCTS OR SERVICES. AS WITH
                                THE
                                PURCHASE OF A PRODUCT OR SERVICE THROUGH ANY MEDIUM OR IN ANY ENVIRONMENT, YOU SHOULD
                                USE
                                YOUR BEST JUDGEMENT AND EXERCISE CAUTION WHERE APPROPRIATE.
                            </p>
                        </div>
                    </div>

                    <div className="mb-5" id="limitations-of-liability">
                        <h3 className="font-semibold text-xl uppercase my-5">20. Limitations of liability</h3>
                        <div className="text-gray-500 font-sans">
                            <p className="mb-4">
                                IN NO EVENT WILL WE OR OUR DIRECTORS, EMPLOYEES, OR AGENTS BE LIABLE TO YOU OR ANY THIRD
                                PARTY FOR ANY DIRECT, INDIRECT, CONSEQUENTIAL, EXEMPLARY, INCIDENTAL, SPECIAL, OR
                                PUNITIVE
                                DAMAGES, INCLUDING LOST PROFIT, LOST REVENUE, LOSS OF DATA, OR OTHER DAMAGES ARISING
                                FROM
                                YOUR USE OF THE SERVICES, EVEN IF WE HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH
                                DAMAGES.
                                NOTWITHSTANDING ANYTHING TO THE CONTRARY CONTAINED HEREIN, OUR LIABILITY TO YOU FOR ANY
                                CAUSE WHATSOEVER AND REGARDLESS OF THE FORM OF THE ACTION, WILL AT ALL TIMES BE LIMITED
                                TO
                                THE AMOUNT PAID, IF ANY, BY YOU TO US DURING THE six (6) mONTH PERIOD PRIOR TO ANY CAUSE
                                OF
                                ACTION ARISING. CERTAIN US STATE LAWS AND INTERNATIONAL LAWS DO NOT ALLOW LIMITATIONS ON
                                IMPLIED WARRANTIES OR THE EXCLUSION OR LIMITATION OF CERTAIN DAMAGES. IF THESE LAWS
                                APPLY TO
                                YOU, SOME OR ALL OF THE ABOVE DISCLAIMERS OR LIMITATIONS MAY NOT APPLY TO YOU, AND YOU
                                MAY
                                HAVE ADDITIONAL RIGHTS.
                            </p>
                        </div>
                    </div>

                    <div className="mb-5" id="indemnification">
                        <h3 className="font-semibold text-xl uppercase my-5">21. Indemnification</h3>
                        <div className="text-gray-500 font-sans">
                            <p className="mb-4">
                                You agree to defend, indemnify, and hold us harmless, including our subsidiaries,
                                affiliates, and all of our respective officers, agents, partners, and employees, from
                                and
                                against any loss, damage, liability, claim, or demand, including reasonable attorneys’
                                fees
                                and expenses, made by any third party due to or arising out of: (1) your Contributions;
                                (2)
                                use of the Services; (3) breach of these Legal Terms; (4) any breach of your
                                representations
                                and warranties set forth in these Legal Terms; (5) your violation of the rights of a
                                third
                                party, including but not limited to intellectual property rights; or (6) any overt
                                harmful
                                act toward any other user of the Services with whom you connected via the Services.
                                Notwithstanding the foregoing, we reserve the right, at your expense, to assume the
                                exclusive defence and control of any matter for which you are required to indemnify us,
                                and
                                you agree to cooperate, at your expense, with our defence of such claims. We will use
                                reasonable efforts to notify you of any such claim, action, or proceeding which is
                                subject
                                to this indemnification upon becoming aware of it.
                            </p>
                        </div>
                    </div>

                    <div className="mb-5" id="user-data">
                        <h3 className="font-semibold text-xl uppercase my-5">22. User data</h3>
                        <div className="text-gray-500 font-sans">
                            <p className="mb-4">
                                We will maintain certain data that you transmit to the Services for the purpose of
                                managing
                                the performance of the Services, as well as data relating to your use of the Services.
                                Although we perform regular routine backups of data, you are solely responsible for all
                                data
                                that you transmit or that relates to any activity you have undertaken using the
                                Services.
                                You agree that we shall have no liability to you for any loss or corruption of any such
                                data, and you hereby waive any right of action against us arising from any such loss or
                                corruption of such data.
                            </p>
                        </div>
                    </div>

                    <div className="mb-5" id="electronic-communications-transactions-and-signatures">
                        <h3 className="font-semibold text-xl uppercase my-5">23. Electronic communications, transactions
                            and
                            signatures</h3>
                        <div className="text-gray-500 font-sans">
                            <p className="mb-4">
                                Visiting the Services, sending us emails, and completing online forms constitute
                                electronic
                                communications. You consent to receive electronic communications, and you agree that all
                                agreements, notices, disclosures, and other communications we provide to you
                                electronically,
                                via email and on the Services, satisfy any legal requirement that such communication be
                                in
                                writing. YOU HEREBY AGREE TO THE USE OF ELECTRONIC SIGNATURES, CONTRACTS, ORDERS, AND
                                OTHER
                                RECORDS, AND TO ELECTRONIC DELIVERY OF NOTICES, POLICIES, AND RECORDS OF TRANSACTIONS
                                INITIATED OR COMPLETED BY US OR VIA THE SERVICES. You hereby waive any rights or
                                requirements under any statutes, regulations, rules, ordinances, or other laws in any
                                jurisdiction which require an original signature or delivery or retention of
                                non-electronic
                                records, or to payments or the granting of credits by any means other than electronic
                                means.
                            </p>
                        </div>
                    </div>

                    <div className="mb-5" id="miscellaneous">
                        <h3 className="font-semibold text-xl uppercase my-5">24. Miscellaneous</h3>
                        <div className="text-gray-500 font-sans">
                            <p className="mb-4">
                                These Legal Terms and any policies or operating rules posted by us on the Services or in
                                respect to the Services constitute the entire agreement and understanding between you
                                and
                                us. Our failure to exercise or enforce any right or provision of these Legal Terms shall
                                not
                                operate as a waiver of such right or provision. These Legal Terms operate to the fullest
                                extent permissible by law. We may assign any or all of our rights and obligations to
                                others
                                at any time. We shall not be responsible or liable for any loss, damage, delay, or
                                failure
                                to act caused by any cause beyond our reasonable control. If any provision or part of a
                                provision of these Legal Terms is determined to be unlawful, void, or unenforceable,
                                that
                                provision or part of the provision is deemed severable from these Legal Terms and does
                                not
                                affect the validity and enforceability of any remaining provisions. There is no joint
                                venture, partnership, employment or agency relationship created between you and us as a
                                result of these Legal Terms or use of the Services. You agree that these Legal Terms
                                will
                                not be construed against us by virtue of having drafted them. You hereby waive any and
                                all
                                defences you may have based on the electronic form of these Legal Terms and the lack of
                                signing by the parties hereto to execute these Legal Terms.
                            </p>
                        </div>
                    </div>

                    <div className="mb-5" id="contact-us">
                        <h3 className="font-semibold text-xl uppercase my-5">25. Contact us</h3>
                        <div className="text-gray-500 font-sans">
                            <p className="mb-4">
                                In order to resolve a complaint regarding the Services or to receive further information
                                regarding use of the Services, please contact us at:
                            </p>
                            <a href="mailto:support@litera-library.com"> support@litera-library.com</a>
                        </div>
                    </div>
                </div>
            </div>
        </MainLayout>
    );
};

export default TermsOfUsePage;