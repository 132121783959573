import React, { useState } from 'react';

const DropzoneBorder = () => {
    const [isHovered, setIsHovered] = useState(false);

    return (
            <svg
                className="dropzone-border"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                width="100%"
                height="100%"
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
            >
                <rect
                    x="1"
                    y="1"
                    width="99.7%"
                    height="99.7%"
                    rx="15"
                    stroke="url(#animatedGradient)"
                    strokeWidth="2"
                    strokeMiterlimit="3.8637"
                    strokeLinecap="round"
                    strokeDasharray="8 8"
                />
                <defs>
                    <linearGradient
                        id="animatedGradient"
                        gradientUnits="objectBoundingBox"
                        x1="0"
                        y1="0"
                        x2="1"
                        y2="1"
                    >
                        <stop offset="0%" stopColor="#488FEE"/>
                        <stop offset="50%" stopColor="#a0f5e5"/>
                        <stop offset="100%" stopColor="#75E0F7"/>
                        <animateTransform
                            attributeName="gradientTransform"
                            type="rotate"
                            from="0 0.5 0.5"
                            to="360 0.5 0.5"
                            dur={isHovered ? "0s" : "4s"}
                            repeatCount="indefinite"
                        />
                    </linearGradient>
                </defs>
            </svg>
    );
};

export default DropzoneBorder;