import React from 'react';

const CloudIcon = () => {
    return (
        <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="currentColor"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M13.8822 5.21875H15.674L11.7594 9.69292L16.3646 15.7812H12.7588L9.93451 12.0887L6.70293 15.7812H4.91001L9.09709 10.9956L4.67926 5.21875H8.37668L10.9296 8.59388L13.8822 5.21875ZM13.2533 14.7088H14.2462L7.83718 6.23492H6.77172L13.2533 14.7088Z"
            />
        </svg>

    );
};

export default CloudIcon;