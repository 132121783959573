import React, {useEffect, useRef, useState} from 'react';
import {sendFile} from "../../api/files";
import FileFormats from "../FileFormats";
import CloudIcon from "../../icons/CloudIcon";
import CloudIconLoading from "../../icons/CloudIconLoading";
import CloudIconSuccess from "../../icons/CloudIconSuccess";
import CloudIconError from "../../icons/CloudIconError";
import { ReactComponent as TryAgainIcon } from "../../img/try-again-icon.svg";
import DropzoneBorder from "../../components/DropzoneBorder";
import {FILE_FORMATS} from "../../constants/formats";
import './FileInput.css';

const FileInput = () => {
    const [selectedFile, setSelectedFile] = useState(null);
    const [isDragOver, setIsDragOver] = useState(false);
    const [loading, setLoading] = useState(false);
    const [dropzoneShow, setDropzoneShow] = useState(true);
    const [responseSuccess, setResponseSuccess] = useState(false);
    const [responseError, setResponseError] = useState(false);
    const [fileInputBgColor, setFileInputBgColor] = useState('bg-grey-50');
    const [fileInputBorder, setFileInputBorder] = useState('file-input mobile-padding-default');
    const [isFileInputDisabled, setFileInputDisabled] = useState(false);
    const [errorMessage, setErrorMessage] = useState('Something went wrong, we are solving the problem');
    const [loadingProgress, setLoadingProgress] = useState(0);

    const fileInputRef = useRef(null);

    const acceptFileFormats = FILE_FORMATS.map(ext => `.${ext}`).join(',');

    const handleFileInputChange = (event) => {
        const file = event.target.files[0];
        setSelectedFile(file);
    };

    useEffect(() => {
        const handleSubmit = async () => {
            if (selectedFile) {
                await fileSubmit(selectedFile);
            }
        }

        handleSubmit();
    }, [selectedFile]);

    const handleDragEnter = (e) => {
        e.preventDefault();
        e.stopPropagation();

        if (isFileInputDisabled) {
            return;
        }

        if (!isDragOver) {
            setIsDragOver(true);
            setFileInputBgColor('bg-sky-50');
            setFileInputBorder('file-input-drag');
        }
    };

    const handleDragLeave = (e) => {
        e.preventDefault();
        e.stopPropagation();

        if (e.currentTarget.contains(e.relatedTarget)) {
            return;
        }

        setIsDragOver(false);
        setFileInputBgColor('bg-white');
        setFileInputBorder('file-input');
    };

    const handleDragOver = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setIsDragOver(true);
    };

    const handleDrop = async (e) => {
        e.preventDefault();
        e.stopPropagation();

        if (isFileInputDisabled) {
            return;
        }

        if (e.dataTransfer.files.length > 1) {
            setResponseError(true);
            setFileInputBgColor('bg-red-50');
            setFileInputBorder('border-solid border-2 border-red-400 rounded-2xl');
            setErrorMessage("Please drop only one file at a time.");
            setDropzoneShow(false);
            return;
        }

        setIsDragOver(false);
        setDropzoneShow(false);

        const file = e.dataTransfer.files[0];

        setNewFileOnInput(file);
        setSelectedFile(file);
    };


    const handleBrowseClick = () => {
        if (!isFileInputDisabled) {
            fileInputRef.current.click();
        }
    };

    const setNewFileOnInput = (file) => {
        const fileList = new DataTransfer();
        fileList.items.add(file);
        fileInputRef.current.files = fileList.files;
    };

    const fileSubmit = async (file) => {
        setFileInputDisabled(true);
        setLoading(true);
        setResponseError(false);
        setDropzoneShow(false);
        setFileInputBgColor('bg-sky-50');
        setFileInputBorder('border-solid border-blue-500 border-upload border-2 rounded-2xl mobile-padding');
        setLoadingProgress(0);

        try {
            const extension = file.name.split('.').pop().toLowerCase();

            if (file.size > 100 * 1024 * 1024) {
                setResponseError(true);
                setDropzoneShow(false);
                setFileInputBgColor('bg-red-50');
                setFileInputBorder('border-red-400 border-error border-2 rounded-2xl mobile-padding');
                setErrorMessage("Your file is too big. It should not exceed 100MB");
                setFileInputDisabled(true);
                return;
            }

            if (!FILE_FORMATS.includes(extension)) {
                setResponseError(true);
                setDropzoneShow(false);
                setFileInputBgColor('bg-red-50');
                setFileInputBorder('border-red-400 border-error border-2 rounded-2xl mobile-padding');
                setErrorMessage("File format not supported");
                setFileInputDisabled(true);
                return;
            }

            const linkToReader = await sendFile(file, setLoadingProgress);

            if (linkToReader.success) {
                setLoading(false);
                setDropzoneShow(false);
                setFileInputBgColor('bg-green-50');
                setFileInputBorder('border-green-400 border-success border-2 rounded-2xl mobile-padding');
                setResponseSuccess(true);

                window.location.href = linkToReader.link;
            }
        } catch (error) {
            setResponseError(true);
            setFileInputDisabled(true);
            setDropzoneShow(false);
            setFileInputBgColor('bg-red-50');
            setFileInputBorder('border-red-400 border-error border-2 rounded-2xl mobile-padding');
        } finally {
            setLoading(false);
        }
    };

    const getFileSize = () => {
        return Math.round(selectedFile.size / (1024 * 1024) * 100) / 100;
    };

    const onTryAgain = () => {
        window.location.reload();
    }

    return (
        <div
            className={`flex items-center justify-center w-full h-full file-input-wrapper`}
            onDragEnter={handleDragEnter}
            onDragLeave={handleDragLeave}
            onDragOver={handleDragOver}
            onDrop={handleDrop}
        >
            <label
                htmlFor="dropzone-file"
                onClick={handleBrowseClick}
                className={`${fileInputBgColor} ${fileInputBorder} cursor-pointer flex flex-col items-center justify-center w-full h-full`}
            >
                {!isDragOver && !responseError && dropzoneShow && <DropzoneBorder />}

                <div className="cloud-icon">
                    {!loading && !responseSuccess && !responseError && <CloudIcon/>}
                </div>
                {loading && (<CloudIconLoading/>)}
                {responseSuccess && (<CloudIconSuccess/>)}
                {responseError && (<CloudIconError/>)}

                {!selectedFile && !responseError && (
                    <div className="flex flex-col items-center">
                        <p className="text-xl my-5 file-input-text neutral-black">Drag & Drop your file here or</p>

                        <button type="button"
                                className="text-white file-input-btn font-bold rounded-2xl text-xl px-10 py-3 text-center me-2 mb-2">
                            Browse file
                        </button>

                        <FileFormats/>
                    </div>
                )}

                {!selectedFile && responseError && (
                    <div className="text-center">
                        {responseError && (
                            <div className="font-semibold my-4">
                                {errorMessage}
                                <button
                                    className="try-again flex justify-center items-center mt-4 mx-auto"
                                    onClick={onTryAgain}
                                >
                                    <TryAgainIcon/>
                                    <span className="neutral-dark-grey">Try again</span>
                                </button>
                            </div>
                        )}
                    </div>
                )}

                {selectedFile && (
                    <div className="text-center info-block">
                        {loading && (
                            <div className="font-semibold my-4 neutral-black">Uploading file {loadingProgress}%...</div>
                        )}
                        {responseSuccess && (
                            <div className="font-semibold my-4 neutral-black">You will now be redirected to your
                                book...</div>
                        )}
                        {responseError && (
                            <div className="font-semibold my-4 flex flex-col neutral-black">
                                {errorMessage}
                                <button
                                    className="try-again flex justify-center items-center mt-4"
                                    onClick={onTryAgain}
                                >
                                    <TryAgainIcon/>
                                    <span className="neutral-dark-grey">Try again</span>
                                </button>
                            </div>
                        )}
                        {!responseError && (
                            <p className="neutral-dark-grey">
                                {selectedFile.name.length > 40 ?
                                    `${selectedFile.name.slice(0, 40)}...` :
                                    selectedFile.name} - {getFileSize()} MB
                            </p>
                        )}
                    </div>
                )}

                <input
                    ref={fileInputRef}
                    type="file"
                    className="hidden"
                    accept={acceptFileFormats}
                    disabled={isFileInputDisabled}
                    onChange={handleFileInputChange}
                />
            </label>
        </div>
    );
};

export default FileInput;