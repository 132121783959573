import { Routes, Route } from "react-router-dom";
import HomePage from "./pages/HomePage/HomePage";
import HowUseApiPage from "./pages/HowUseApiPage";
import TermsOfUsePage from "./pages/TermsOfUsePage";

function App() {
  return (
    <Routes>
      <Route path="/" element={ <HomePage /> } />
      <Route path="/api-usage" element={ <HowUseApiPage /> } />
      <Route path="/terms" element={ <TermsOfUsePage /> } />
    </Routes>
  );
}

export default App;
