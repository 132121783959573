import React from 'react';

const CloudIconSuccess = () => {
    return (
        <svg width="97" height="96" viewBox="0 0 97 96" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_21076_156)">
                <mask id="mask0_21076_156" maskUnits="userSpaceOnUse" x="0" y="0" width="97"
                      height="96">
                    <path d="M96.5 0H0.5V96H96.5V0Z" fill="white"/>
                </mask>
                <g mask="url(#mask0_21076_156)">
                    <mask id="mask1_21076_156" maskUnits="userSpaceOnUse" x="0" y="0"
                          width="97" height="96">
                        <path d="M0.5 0H96.5V96H0.5V0Z" fill="white"/>
                    </mask>
                    <g mask="url(#mask1_21076_156)">
                        <path
                            d="M49.6343 72.6139C58.993 72.6139 67.6962 72.6139 69.0121 72.6139C79.4734 72.6139 87.9547 64.1334 87.9547 53.6721C87.9547 45.6542 82.9702 38.8053 75.9337 36.0407C71.9275 26.6491 62.6117 20.0651 51.7565 20.0651C41.6109 20.0651 32.8147 25.8193 28.4366 34.2388C27.6411 33.9802 26.7933 33.8372 25.9111 33.8372C21.3954 33.8372 17.7354 37.498 17.7354 42.0137C17.7354 42.6185 17.8057 43.2061 17.9299 43.7734C13.0642 46.3348 9.7417 51.4326 9.7417 57.3134C9.7417 65.7634 16.5922 72.6139 25.0422 72.6139C27.4294 72.6139 38.9581 72.6139 49.6343 72.6139Z"
                            stroke="#8895A7" strokeWidth="2.73488" strokeLinecap="round" strokeLinejoin="round"/>
                        <path
                            d="M33.6695 38.9882C32.4372 36.0236 29.5218 33.9349 26.1118 33.9349M76.0688 35.9572C77.4667 39.1679 78.2418 42.7116 78.2418 46.4373C78.2418 46.4373 78.2504 49.4167 77.1799 52.99"
                            stroke="#8895A7" strokeWidth="2.73488" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M34.5181 50.5536L45.2623 61.4932C45.2623 61.4932 60.4362 46.3591 65.0636 41.7434"
                              stroke="#3DADA2" strokeWidth="2.73488" strokeLinecap="round" strokeLinejoin="round"/>
                    </g>
                </g>
            </g>
            <defs>
                <clipPath id="clip0_21076_156">
                    <rect width="96" height="96" fill="white" transform="translate(0.5)"/>
                </clipPath>
            </defs>
        </svg>

    );
};

export default CloudIconSuccess;