import TwitterIcon from "../img/tw.svg";
import WhatsappIcon from "../img/whatsapp.svg";
import TelegramIcon from "../img/telegram.svg";
import EmailIcon from "../img/mail.svg";
import FacebookIcon from "../img/fb.svg";
import CopyLinkIcon from "../img/copy.svg";
import ShareLinkIcon from "../img/share.svg";

const baseUrl = process.env.NODE_ENV === 'production'
    ? process.env.REACT_APP_BASE_URL_PROD
    : process.env.REACT_APP_BASE_URL_DEV;

export const SHARED_LINKS = [
    {
        icon: TwitterIcon,
        altText: 'twitter',
        linkUrl: `${process.env.REACT_APP_TWITTER_URL}${baseUrl}&text=Litera%20Reader:%20Read%20Anything%20Anytime%20Anywhere`,
        tooltipText: 'Share on Twitter',
    },
    {
        icon: WhatsappIcon,
        altText: 'whatsApp',
        linkUrl: `${process.env.REACT_APP_WHATSAPP_URL}Litera%20Reader:%20Read%20Anything%20Anytime%20Anywhere`,
        tooltipText: 'Send to WhatsApp',
    },
    {
        icon: TelegramIcon,
        altText: 'telegram',
        linkUrl: `${process.env.REACT_APP_TELEGRAM_URL}${baseUrl}&text=Litera%20Reader:%20Read%20Anything%20Anytime%20Anywhere`,
        tooltipText: 'Send to Telegram',
    },
    {
        icon: EmailIcon,
        altText: 'mail',
        linkUrl: `mailto:${baseUrl}/`,
        tooltipText: 'Send via email',
    },
    {
        icon: FacebookIcon,
        altText: 'facebook',
        linkUrl: `${process.env.REACT_APP_FACEBOOK_URL}${baseUrl}/`,
        tooltipText: 'Share on Facebook',
    },
    {
        icon: CopyLinkIcon,
        altText: 'copy',
        linkUrl: `${baseUrl}/`,
        tooltipText: 'Copy link',
    },
    {
        icon: ShareLinkIcon,
        altText: 'share',
        linkUrl: `${baseUrl}/`,
        tooltipText: 'Share',
    }
];