import React, {useState} from 'react';
import Notification from '../Notification';
import './ShareLink.css';

const ShareLink = ({ Icon, altText, linkUrl, tooltipText }) => {
    const [notificationMessage, setNotificationMessage] = useState('');
    const [showNotification, setShowNotification] = useState(false);

    const openNewWindow = (url, linkText) => {
        const width = 700;
        const height = 600;
        const left = (window.innerWidth - width) / 2;
        const top = (window.innerHeight - height) / 2;
        const options = `width=${width},height=${height},left=${left},top=${top}`;

        if (linkText === 'copy') {
            navigator.clipboard.writeText(url)
                .then(() => {
                    setShowNotification(true);
                    setNotificationMessage('Copied link!');
                })
                .catch(() => {
                    setShowNotification(true);
                    setNotificationMessage('Something went wrong');
                });
        } else if (linkText === 'share') {
            navigator.share({
                title: 'Litera Reader: Read Anything Anytime Anywhere',
                url: linkUrl
            });
        } else {
            window.open(url, '_blank', options);
        }

        setShowNotification(false);
    };

    return (
        <div>
            {showNotification &&
                <Notification message={notificationMessage}/>
            }

            <button
                className={`${altText} relative link`}
                title={tooltipText}
                onClick={() => openNewWindow(linkUrl, altText)}
            >
                <img src={Icon} alt={altText} className="link-icon"/>
            </button>
        </div>
    );
};

export default ShareLink;