import React from 'react';

const CloudIconLoading = () => {
    return (
        <lord-icon
            src="https://cdn.lordicon.com/hqdtqsrl.json"
            trigger="loop"
            colors="primary:#8895A7,secondary:#488FEE"
            style={{width: '96px', height: '96px'}}
        ></lord-icon>
    );
};

export default CloudIconLoading;