export const TABLE_OF_CONTENTS_LIST = [
    {
        "title": "OUR SERVICES",
        "link": "#our-services"
    },
    {
        "title": "INTELLECTUAL PROPERTY RIGHTS",
        "link": "#intellectual-property-rights"
    },
    {
        "title": "USER REPRESENTATIONS",
        "link": "#user-representations"
    },
    {
        "title": "USER REGISTRATION",
        "link": "#user-registration"
    },
    {
        "title": "PURCHASES AND PAYMENT",
        "link": "#purchases-and-payment"
    },
    {
        "title": "CANCELLATION",
        "link": "#cancellation"
    },
    {
        "title": "PROHIBITED ACTIVITIES",
        "link": "#prohibited-activities"
    },
    {
        "title": "USER GENERATED CONTRIBUTIONS",
        "link": "#user-generated-contributions"
    },
    {
        "title": "CONTRIBUTION LICENCE",
        "link": "#contribution-licence"
    },
    {
        "title": "GUIDELINES FOR REVIEWS",
        "link": "#guidelines-for-reviews"
    },
    {
        "title": "THIRD-PARTY WEBSITES AND CONTENT",
        "link": "#third-party-websites-and-content"
    },
    {
        "title": "SERVICES MANAGEMENT",
        "link": "#services-management"
    },
    {
        "title": "COPYRIGHT INFRINGEMENTS",
        "link": "#copyright-infractions"
    },
    {
        "title": "TERM AND TERMINATION",
        "link": "#term-and-termination"
    },
    {
        "title": "MODIFICATIONS AND INTERRUPTIONS",
        "link": "#modifications-and-interruptions"
    },
    {
        "title": "GOVERNING LAW",
        "link": "#governing-law"
    },
    {
        "title": "DISPUTE RESOLUTION",
        "link": "#dispute-resolution"
    },
    {
        "title": "CORRECTIONS",
        "link": "#corrections"
    },
    {
        "title": "DISCLAIMER",
        "link": "#disclaimer"
    },
    {
        "title": "LIMITATIONS OF LIABILITY",
        "link": "#limitations-of-liability"
    },
    {
        "title": "INDEMNIFICATION",
        "link": "#indemnification"
    },
    {
        "title": "USER DATA",
        "link": "#user-data"
    },
    {
        "title": "ELECTRONIC COMMUNICATIONS, TRANSACTIONS, AND SIGNATURES",
        "link": "#electronic-communications-transactions-and-signatures"
    },
    {
        "title": "MISCELLANEOUS",
        "link": "#miscellaneous"
    },
    {
        "title": "CONTACT US",
        "link": "#contact-us"
    }
]


export const PROHIBITED_ACTIVES = [
    'Systematically retrieve data or other content from the Services to create or compile, directly or indirectly, a collection, compilation, database, or directory without written permission from us.',
    'Trick, defraud, or mislead us and other users, especially in any attempt to learn sensitive account information such as user passwords.',
    'Circumvent, disable, or otherwise interfere with security-related features of the Services, including features that prevent or restrict the use or copying of any Content or enforce limitations on the use of the Services and/or the Content contained therein.',
    'Disparage, tarnish, or otherwise harm, in our opinion, us and/or the Services.',
    'Use any information obtained from the Services in order to harass, abuse, or harm another person.',
    'Make improper use of our support services or submit false reports of abuse or misconduct.',
    'Use the Services in a manner inconsistent with any applicable laws or regulations.',
    'Engage in unauthorised framing of or linking to the Services.',
    'Upload or transmit (or attempt to upload or to transmit) viruses, Trojan horses, or other material, including excessive use of capital letters and spamming (continuous posting of repetitive text), that interferes with any party’s uninterrupted use and enjoyment of the Services or modifies, impairs, disrupts, alters, or interferes with the use, features, functions, operation, or maintenance of the Services.',
    'Engage in any automated use of the system, such as using scripts to send comments or messages, or using any data mining, robots, or similar data gathering and extraction tools.',
    'Deletе the copyright or other proprietary rights notice from any Content.',
    'Attempt to impersonate another user or person or use the username of another user.',
    'Upload or transmit (or attempt to upload or to transmit) any material that acts as a passive or active information collection or transmission mechanism, including without limitation, clear graphics interchange formats (\'gifs\'), 1×1 pixels, web bugs, cookies, or other similar devices (sometimes referred to as \'spyware\' or \'passive collection mechanisms\' or \'pcms\').',
    'Interfere with, disrupt, or create an undue burden on the Services or the networks or services connected to the Services.',
    'Harass, annoy, intimidate, or threaten any of our employees or agents engaged in providing any portion of the Services to you.',
    'Attempt to bypass any measures of the Services designed to prevent or restrict access to the Services, or any portion of the Services.',
    'Copy or adapt the Services\' software, including but not limited to Flash, PHP, HTML, JavaScript, or other code.',
    'Except as permitted by applicable law, decipher, decompile, disassemble, or reverse engineer any of the software comprising or in any way making up a part of the Services.',
    'Except as may be the result of standard search engine or Internet browser usage, use, launch, develop, or distribute any automated system, including without limitation, any spider, robot, cheat utility, scraper, or offline reader that accesses the Services, or use or launch any unauthorised script or other software.',
    'Use a buying agent or purchasing agent to make purchases on the Services.',
    'Make any unauthorised use of the Services, including collecting usernames and/or email addresses of users by electronic or other means for the purpose of sending unsolicited email, or creating user accounts by automated means or under false pretences.',
    'Use the Services as part of any effort to compete with us or otherwise use the Services and/or the Content for any revenue-generating endeavour or commercial enterprise.',
    'Sell or otherwise transfer your profile',
    'Use the Services to advertise or offer to sell goods and services.'
];

export const USER_GENERATED_CONTRIBUTIONS = [
    'The creation, distribution, transmission, public display, or performance, and the accessing, downloading, or copying of your Contributions do not and will not infringe the proprietary rights, including but not limited to the copyright, patent, trademark, trade secret, or moral rights of any third party.',
    'You are the creator and owner of or have the necessary licences, rights, consents, releases, and permissions to use and to authorise us, the Services, and other users of the Services to use your Contributions in any manner contemplated by the Services and these Legal Terms.',
    'You have the written consent, release, and/or permission of each and every identifiable individual person in your Contributions to use the name or likeness of each and every such identifiable individual person to enable inclusion and use of your Contributions in any manner contemplated by the Services and these Legal Terms./or the Content contained therein.',
    'Your Contributions are not false, inaccurate, or misleading.',
    'Your Contributions are not unsolicited or unauthorised advertising, promotional materials, pyramid schemes, chain letters, spam, mass mailings, or other forms of solicitation.',
    'Your Contributions are not obscene, lewd, lascivious, filthy, violent, harassing, libellous, slanderous, or otherwise objectionable (as determined by us).',
    'Your Contributions do not ridicule, mock, disparage, intimidate, or abuse anyone.',
    'Your Contributions are not used to harass or threaten (in the legal sense of those terms) any other person and to promote violence against a specific person or class of people.',
    'Your Contributions do not violate any applicable law, regulation, or rule.',
    'Your Contributions do not violate the privacy or publicity rights of any third party.',
    'Your Contributions do not violate any applicable law concerning child pornography, or otherwise intended to protect the health or well-being of minors.',
    'Your Contributions do not include any offensive comments that are connected to race, national origin, gender, sexual preference, or physical handicap.',
    'Your Contributions do not otherwise violate, or link to material that violates, any provision of these Legal Terms, or any applicable law or regulation.',
]