export const FILE_FORMATS = [
    'pdf',
    'epub',
    'mobi',
    'fb2',
    'azw3',
    'djvu',
    'lit',
    'txt',
    'rtf',
    'lrf',
    'azw',
    'html',
    'docx',
    'pdb',
    'chm',
    'cbz',
    'prc',
    'htmlz',
    'odt',
    'djv',
    'azw4',
    'pml',
    'rb',
    'tcr',
];