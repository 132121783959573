import React from 'react';
import ShareLink from './ShareLink';
import {SHARED_LINKS} from "../../constants/sharedLinks";

const SharedLinks = () => {
    return (
        <div className="flex items-center gap-3 links-block relative">
            <span className="neutral-black share-project">Share project</span>

            <div className="flex justify-between gap-6 links-block-icons">
                {SHARED_LINKS.map((link, index) =>
                    <ShareLink
                        key={index}
                        Icon={link.icon}
                        altText={link.altText}
                        linkUrl={link.linkUrl}
                        tooltipText={link.tooltipText}
                    />
                )}
            </div>
        </div>
    );
};

export default SharedLinks;