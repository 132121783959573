import React from 'react';

const CloudIcon = () => {
    return (
        <svg width="96" height="96" viewBox="0 0 96 96" fill="none" xmlns="http://www.w3.org/2000/svg">
            <mask id="mask0_21093_2967"  maskUnits="userSpaceOnUse" x="0" y="0"
                  width="96" height="96">
                <path d="M96 0H0V96H96V0Z" fill="white"/>
            </mask>
            <g mask="url(#mask0_21093_2967)">
                <mask id="mask1_21093_2967"  maskUnits="userSpaceOnUse" x="0" y="0"
                      width="96" height="96">
                    <path d="M0 0H96V96H0V0Z" fill="white"/>
                </mask>
                <g mask="url(#mask1_21093_2967)">
                    <path
                        d="M56.9932 72.614C62.8701 72.614 67.435 72.614 68.3594 72.614C78.8207 72.614 87.302 64.1335 87.302 53.6722C87.302 45.6543 82.3175 38.8053 75.281 36.0408C71.2748 26.6492 61.959 20.0651 51.1038 20.0651C40.9582 20.0651 32.162 25.8193 27.7839 34.2389C26.9884 33.9802 26.1406 33.8372 25.2584 33.8372C20.7427 33.8372 17.0827 37.4981 17.0827 42.0137C17.0827 42.6185 17.153 43.2061 17.2772 43.7734C12.4115 46.3349 9.08899 51.4327 9.08899 57.3135C9.08899 65.7635 15.9395 72.614 24.3895 72.614C26.4203 72.614 35.0657 72.614 44.1744 72.614"
                        stroke="#B7C2CC" strokeWidth="2.73488" strokeLinecap="round" strokeLinejoin="round"/>
                    <path
                        d="M32.7888 38.4874C31.4487 35.7916 28.674 33.935 25.4594 33.935M75.4163 35.9572C76.8142 39.168 77.5894 42.7116 77.5894 46.4373C77.5894 46.4373 77.5995 49.7137 76.3626 53.516"
                        stroke="#B7C2CC" strokeWidth="2.73488" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M50.7352 76.1546L50.7387 43.6859" stroke="#B7C2CC" strokeWidth="2.73488"
                          strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M39.8933 54.1649L50.8328 43.2254L61.577 54.1649" stroke="#B7C2CC"
                          strokeWidth="2.73488" strokeLinecap="round" strokeLinejoin="round"/>
                </g>
            </g>
        </svg>
    );
};

export default CloudIcon;