import React from 'react';

const CloudIcon = () => {
    return (
        <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="currentColor"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M6.15067 6.56744L8.08955 7.88587L7.36247 5.71987L9.30135 4.35435H6.90199L6.15067 2.23543L5.42359 4.35435H3L4.93888 5.71987L4.2118 7.88587L6.15067 6.56744ZM9.37405 8.19194L8.81663 8.30965L9.34982 8.52155V9.06305L9.71336 8.66281L10.2465 8.85115L9.93148 8.40383L10.2708 7.98005L9.71336 8.09776L9.37405 7.65044V8.19194ZM10.1981 6.59098L9.78607 6.94413L10.3435 6.89705L10.5859 7.415L10.6828 6.8735L11.2402 6.82642L10.7555 6.54389L10.8767 6.00239L10.4647 6.35555L9.97995 6.07302L10.1981 6.59098ZM10.3435 4.75459L10.5131 5.29609L10.6828 4.77813H11.2402L10.804 4.44852L10.9736 3.90702L10.5131 4.23663L10.0769 3.88348L10.2465 4.42498L9.78607 4.73104L10.3435 4.75459ZM9.37405 2.87111V3.41261L9.71336 2.96528L10.2708 3.083L9.93148 2.65922L10.2465 2.21189L9.71336 2.42378L9.34982 2V2.5415L8.81663 2.75339L9.37405 2.87111Z"
            />
            <path
                d="M16.28 8.24033L14.4005 17.4753C14.4005 17.4753 14.1375 18.1156 13.4151 17.8085L9.07858 14.5678L9.05847 14.5583C9.64424 14.0457 14.1865 10.0652 14.385 9.88478C14.6924 9.60534 14.5016 9.43899 14.1448 9.65007L7.43523 13.8029L4.84671 12.9541C4.84671 12.9541 4.43935 12.8128 4.40017 12.5057C4.36046 12.1982 4.86012 12.0318 4.86012 12.0318L15.4127 7.99708C15.4127 7.99708 16.28 7.62567 16.28 8.24033Z"
            />
        </svg>

    );
};

export default CloudIcon;