import React from 'react';
import './MainLayout.css';
import Header from "../../components/Header";
import Footer from "../../components/Footer";

const MainLayout = ({ children }) => {
    return (
        <div className="flex flex-col justify-between min-h-screen layout">
            <div className="main">
                <Header/>
                <main className="h-full">{children}</main>
            </div>
            <Footer/>
        </div>
    );
};

export default MainLayout;